import { BaseParams, BaseParamsInterface } from '@app/store/models';
import { UsersValue } from './users.value';

interface ParamsType extends BaseParamsInterface {
    //
}

export class UsersValueDto extends BaseParams {
    private _params: ParamsType;

    constructor(dto: UsersValue) {
        super(dto);

        this._params = { ...this.baseParams };
    }

    get params(): Record<string, string> {
        return Object.fromEntries(Object.entries(this._params).filter(([, value]) => value !== undefined && value !== null));
    }
}
