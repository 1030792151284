import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UsersState } from '../reducers/users.reducer';
import { USERS_FEATURE_KEY } from '../keys';

const selectState = createFeatureSelector<UsersState>(USERS_FEATURE_KEY);

const selectLoading = createSelector(selectState, (state: UsersState) => state.loaded === null);
const selectUsers = createSelector(selectState, (state: UsersState) => state.users);
const selectTotalItems = createSelector(selectState, (state: UsersState) => state.totalItems);

export { selectLoading, selectUsers, selectTotalItems };
