import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { UsersFacade } from '../facades/users.facade';

export function usersCleanup<T>(): CanDeactivateFn<T> {
    return () => {
        const usersFacade = inject(UsersFacade);
        usersFacade.clear();
        return true;
    };
}
