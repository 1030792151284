import { createReducer, on } from '@ngrx/store';
import { usersActions } from '../actions/users.actions';
import { User } from '../models';

export interface UsersState {
    loaded: boolean | null;
    users: User[] | null;
    totalItems: number | null;
}

export const initialState: UsersState = {
    loaded: null,
    users: null,
    totalItems: null,
};

export const usersReducer = createReducer(
    initialState,
    on(
        usersActions.getUsers,
        (state): UsersState => ({
            ...state,
            loaded: null,
        }),
    ),
    on(
        usersActions.getUsersSuccess,
        (state, payload): UsersState => ({
            ...state,
            loaded: true,
            users: payload.items,
            totalItems: payload.totalItems,
        }),
    ),
    on(
        usersActions.getUsersError,
        (state): UsersState => ({
            ...state,
            loaded: false,
            totalItems: 0,
        }),
    ),
    on(
        usersActions.clear,
        (): UsersState => ({
            ...initialState,
        }),
    ),
);
