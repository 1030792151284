import { Injectable, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs';
import { selectUsers, selectLoading, selectTotalItems } from '../selectors/users.selector';
import { usersActions } from '../actions/users.actions';
import { User, UsersValue } from '../models';

@Injectable({
    providedIn: 'root',
})
export class UsersFacade {
    private readonly store = inject(Store);

    loading$ = this.store.select(selectLoading);
    totalItems$ = this.store.select(selectTotalItems);
    users$ = this.store.select(selectUsers).pipe(filter((data): data is User[] => data !== null));

    getCustomers(params: UsersValue): void {
        this.store.dispatch(usersActions.getUsers({ params }));
    }

    clear(): void {
        this.store.dispatch(usersActions.clear());
    }
}
