import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { User, UsersValue } from '../models';

export const usersActions = createActionGroup({
    source: 'Users',
    events: {
        'Get Users': props<{ params: UsersValue }>(),
        'Get Users Success': props<{ items: User[]; totalItems: number }>(),
        'Get Users Error': emptyProps(),

        Clear: emptyProps(),
    },
});
